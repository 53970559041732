<template>
  <div>
    <div class="navigation">
      <!-- <img src="../assets/img/LOGO.png" alt=""> -->
      <svg-icon icon-class="logo" class="logo" />
      <div class="navbar">
        <router-link v-for="(item, index) in navbar" :key="index" :to="item.path">
          <span>{{item.text}}</span>
        </router-link>
      </div>
    </div>
    <router-view>
    </router-view>
  </div>

</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      navbar: [
        {
          text: '首页',
          path: '/home'
        },
        {
          text: '走进仁信',
          path: '/walkIn'
        },
        {
          text: '信息披露',
          path: '/msg'
        },
        {
          text: '新闻中心',
          path: '/news'
        },
        // {
        //   text: '服务领域',
        //   path: '/serve'
        // },
        // {
        //   text: '保险产品',
        //   path: '/insurance'
        // },
        {
          text: '联系我们',
          path: '/connect'
        }
      ]
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.navigation {
  width: 1180px;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  .logo {
    margin-right: 130px;
    width: 341px;
    height: 38px;
  }
  .navbar {
    display: flex;
    // width: 100%;
    a {
      width: 100px;
      text-align: center;
      font-size: 1.125rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .router-link-active {
      font-weight: bold;
      color: #2e4e9a;
    }
  }
}
</style>
